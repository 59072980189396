import { useDispatch} from 'react-redux';
import React from 'react';
import { useGetAllProdwwwQuery} from '../slices/productsApi.js';
import { addToCart } from '../slices/cartSlice';
import { www, photo_www } from '../alocal/Titulo.js';
import '../css/zoom2.css';
import '../css/extras.css';




const Pagoo = () =>  {

  var { data, error, isLoading } =   useGetAllProdwwwQuery();

  




 
  const dispatch = useDispatch()



  // const {items, status }= useSelector(state=>state.products);
   const handleAddToCart = product => {
    dispatch(addToCart(product))


  }
  const imgs = '../gorba/apeti/';

  return (
   
    <div className='mai'>

    {isLoading ? (
      <p> Loading...</p>
    ) : error ? (
      <p>An error occured</p>
    ) : (
      <>
     
        <div className='bdiv'>
        <div className='mam'>
            
            <img src={photo_www} alt='logo' />
          </div>

<h2>{www}</h2>

          {data?.map(product => (
            <div key={product.code} >
              
              {(() => {
              if ( (product.llenar === "vacio")||(product.llenar === null)){
               return(<></>)
              }else if (product.imagen !== '') {
                  return (
                    <div>
                      <div className='fotb'></div>
                      <div className='ma2'>
                   
                        <img src={imgs+product.imagen} alt="imagen" ></img>
                      </div>
                    </div>
                  )
                } else {
                  return <div></div>
                }

              })()}

              {(() => {

            if ( (product.llenar === "")||(product.llenar === null) ){
              return(
            <div>
              <div className='raw'>
                <div className='zumu'>
                  <div className='ma8'>
                    
                    <p className='titol'>{product.titulo}&nbsp;</p>
                  </div>
                  <div className='ma1'>
                    
                    <p className='tipo'> {product.descrip}</p>
                  </div>
                  <div className='ma1'>
                    
                    <p className='titot'> {product.precio}</p>
                  </div>
                </div>
              </div>
              <div className="plussa">
              <button className="sensd" onClick={() => handleAddToCart(product)}>+</button>
              </div>
              </div>

              );

}else{
  <></>
}

            })()}

             {(() => { 
              
               if( product.llenar === "true"){
                return(
                <div class='mafrase'> <p class= 'tit'> {product.frase} </p> </div>);
              }
             })()}


            </div>
          ))}
      </div>
        </>
      )}
    </div>
  )
}

export default Pagoo 