import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../css_cart/cart.css'
import { getTotals,clearCart  } from '../slices/cartSliceGr.js'
import firebaseApp from '../credenciales'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getFirestore, doc, setDoc, updateDoc } from 'firebase/firestore'

function OrderModalGr ({ isOrderModalGr, setIsOrderModalGr, usuarioGlobal }) {
  const firestore = getFirestore(firebaseApp)

  const cart = useSelector(state => state.cart)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTotals())
  }, [cart, dispatch])

  async function mapaTarea () {
    var car = await cart.cartItems.map(
      cartItem => cartItem.titulo + ' ' + cartItem.cartQuantity
    )
    var cma = await car.join('\n')
    const citizen = cma !== '' ? cma : 'Καλέστε Σερβιτόρο'
    return citizen
  }

  async function mapaDone () {
    var car = await cart.cartItems.map(
      cartItem => cartItem.titulo + ' ' + cartItem.cartQuantity
    )
    return car
  }

  async function anadirTarea (e) {
    e.preventDefault()
    const users = usuarioGlobal.uid
    const idFrom = users
    const idTo = 'mZ11tlXbIocuQ0Ih2Dki47p03Xk2'
    const type = 0
    const msgs = e.target.formDescripcion.value

    const city = await mapaTarea()
    var car = await mapaDone()

    var idmm = new Date().setMilliseconds(123)
    var idss = new Date()
    var ids = String(idmm)

    const nvoArrayTareas = [
      {
        content: city,
        idFrom: idFrom,
        idTo: idTo,
        msgs: msgs,
        timestamp: ids,
        createOn: idss,
        type: type
      }
    ]

    const mass = '1'
    const sure = '2'
    const tareasSegun = [
      {
        mass: mass,
        sure: sure
      }
    ]

    const docuRef = doc(
      firestore,
      `messages`,
      `${idTo}-${users}`,
      `${idTo}-${users}`,
      `${ids}`
    )
    const doRef = doc(firestore, `messages`, `${idTo}-${users}`)

    await setDoc(docuRef, ...nvoArrayTareas).then(
      console.log('esto es nvo ', nvoArrayTareas),

      await updateDoc(doRef, ...tareasSegun).then(
        console.log('esto es tareasSegun ', tareasSegun),

        toast.success(`Στείλατε την παραγγελία σας στον Σερβιτόρο`, {
          position: "top-center",
          autoClose: 4000
        })
      )
    )

    await setIsOrderModalGr(false);

    if (car.length === 0) {
      return null
    } else {
      var limas = localStorage.getItem('lista')
      var ligia = JSON.parse(limas)

      for (const color of car) {
        ligia.push(color)
      }
      localStorage.setItem('lista', JSON.stringify(ligia))

      dispatch(clearCart())
    }

    e.target.formDescripcion.value = ''
  }

  return (
    <Modal
      show={isOrderModalGr}
      onHide={() => {
        setIsOrderModalGr(false)
      }}
    >
      <Modal.Header>
        <Modal.Title>
           {/* Your Order  */}
         Η Παραγγελία σας
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {(() => {

if (cart.cartItems.length === 0) {
  return(
    <div>
      {/* Your List Orders is Empty */}
      Η λίστα παραγγελίας είναι κενή
      </div>
  )

}else{
  return(
    <div>
        <div className='cart-it displa w-100'>
          {cart.cartItems &&
            cart.cartItems.map(cartItem => (
              <div key={cartItem.code}>
                <div className='cart-it txal-ce'>
                  {/* <<<<<<< */}

                  <div className='cart-prod txal-ce'>
                    <div>
                      <h3 className='hbb cabece txal-ce w-100'>
                        {cartItem.titulo}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className='cart-prod aling-para w-100'>
                  <div className='cart-prod aling-para roww w-100'>
                    <div className='count '></div>
                    <div className='count txal-ce alin'>
                      {cartItem.cartQuantity}
                    </div>
                    <div className='count'> </div>
                  </div>
                </div>

                <div className='mas-space'> </div>
                <div className='spac-line-modal'></div>
                <div className='m-b20s'> </div>

                {/* <<<<<<< */}
              </div>
            ))}
        </div>

        <form onSubmit={anadirTarea}>
          <div className='alin-cont alin displa w-100 m-t20'>
            <div className='alin-cont alin displa w-100'>
              <input
                type='hidden'
                id='formDescripcion'
                name='formDescripcion'
                placeholder='Enter Suggestion'
              />
            </div>
            <button className='btn-conect alin' type='sumbit'>

              Επιβεβαίωση Παραγγελίας 
            {/* Στείλτε την παραγγελία στον σερβιτόρο */}
            {/* Send the Order to the Waiter */}
            </button>

            <div className='alin-cont alin displa w-100'></div>
          </div>
        </form>

        </div>
     )}
    })()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            setIsOrderModalGr(false)
          }}
        >
        Ακύρωση
          {/* Cancelar */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default OrderModalGr
