import React from 'react'
import {  Link, Outlet } from 'react-router-dom'
import GrNavMenu from '../compo/NavMenuGr.jsx';
import '../css/slide.css'
import '../css/almira.css'



const Arxixi = () => {
 
  return (
   
    <div className='mai'>
          
          <GrNavMenu/> 
         
    <Outlet />
    
    <div className="carta">
    <Link to='/cartgr'>  
             <button className="battt" type="button" id="btnModal"></button>
    </Link>
    </div>
    </div>
         )
}

export default Arxixi
