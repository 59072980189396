import { NavLink } from 'react-router-dom'
import React from 'react'
//import { debounce } from '../utils/helper'
import '../css/slide.css'
import '../css/almira.css'
import '../css_cart/links.css'
import useSticky from "../diseno/sticky";
import classNames from "classnames";

const NavMenuGr = () => {

  const { sticky, stickyRef } = useSticky();
  
  return (
    <div>
      <div className='mov'>
        <div className='mov1'></div>

        <div className='second_body'>
          <div className='logo-al'>
            <img src='https://qrgr.rest/gorba/data/terraza2.jpg' alt='logo' />
          </div>
          <div>
            <div className='conner'>
              <div className='gr'></div>
            </div>
            <div>
            <nav ref={stickyRef} className={classNames("nav flex", { sticky })}>
                
              <div className='mard'>
                <div className='wrapper'>
                  <div className='item'>
                    <NavLink
                      to='/'
                      className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      }
                    >
                      <p></p>
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink
                      to='/arxixi/grpagaa'
                      className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      }
                    >
                      <p>Πρωινό &#38; Τοστ</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagbb'>
                      <p> Μπακέτες &#38; Πίτες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagcc'>
                      {' '}
                      <p>Ορεκτικά</p>{' '}
                    </NavLink>
                  </div>

                  <div className='item'>
                    <NavLink to='/arxixi/grpagdd'>
                      {' '}
                      <p>Σαλάτες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagee'>
                      {' '}
                      <p>Μαγειρευτά</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagff'>
                      {' '}
                      <p>Σούπα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpaggg'>
                      {' '}
                      <p>Μακαρονάδες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpaghh'>
                      {' '}
                      <p>Ψάρια &#38; Θαλασσινά</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagii'>
                      {' '}
                      <p>Γλuκά</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagjj'>
                      <p>Soft Αναψυκτικά</p>{' '}
                    </NavLink>
                  </div>

                  <div className='item'>
                    <NavLink to='/arxixi/grpagkk'>
                      {' '}
                      <p>Χυμοί</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagll'>
                      {' '}
                      <p>Μπύρες</p>{' '}
                    </NavLink>
                  </div>

                  <div className='item'>
                    <NavLink to='/arxixi/grpagmm'>
                      {' '}
                      <p>Οινοκατάλογος</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagnn'>
                      {' '}
                      <p>Ποτά &#38; Cocktails</p>{' '}
                    </NavLink>
                  </div>
                  <div className='item'>
                    <NavLink to='/arxixi/grpagoo'>
                      {' '}
                      <p>Καφές</p>{' '}
                    </NavLink>
                  </div>

                  <div className='item'>
                    <NavLink to='/cart'></NavLink>
                  </div>
                </div>
              </div>
            </nav>
            </div>
            {sticky && (
        <div
          style={{
            height: `${stickyRef.current?.clientHeight}px`
          }}
        />
      )}
        </div>
      </div>
      </div>
    </div>
  )
}

export default NavMenuGr
