
import {  NavLink } from 'react-router-dom'
import React from 'react'

import '../css/slide.css'
import '../css/almira.css'

import '../css_cart/links.css';
import useSticky from "../diseno/sticky";
import classNames from "classnames";

const NavMenu = () => {

const { sticky, stickyRef } = useSticky();

  return (

    <div>
    <div className="mov">
      <div className="mov1"></div>
  
      <div className="second_body">
      <div className="logo-al">
      <img src="https://qrgr.rest/gorba/data/terraza2.jpg" alt="logo"/>
      </div>
      <div>

         <div className='conner'>
            <div className="gr">
            </div>
         </div>
         <nav ref={stickyRef} className={classNames("nav flex", { sticky })}>

         <div className="mard">
            <div className="wrapper">
               <div className="item">
                  <NavLink to='/'
                  className={({ isActive }) => isActive ? 'link active' : 'link'}
                  ><p></p></NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagaa' className={({ isActive }) => isActive ? 'link active' : 'link'}> <p> Breakfast &#38; Toast </p></NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagbb'><p> Baggettes &#38; Pies</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagcc'>  <p>Appetizers</p> </NavLink>
               </div>

               <div className="item">
                  <NavLink to='/ahome/pagdd'>  <p>Salads</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagee'>  <p> Hot pot</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagff'>  <p>Soups</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/paggg'>  <p>Pasta</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/paghh'>  <p>Fish</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagii'>  <p>Sweets</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagjj'><p>Soft Drinks</p> </NavLink>
               </div>

               <div className="item">
                  <NavLink to='/ahome/pagkk'>  <p>Juices</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagll'>  <p>Beers</p> </NavLink>
               </div>

               <div className="item">
                  <NavLink to='/ahome/pagmm'>  <p>Wines</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagnn'> <p>Drinks &#38;  Cocktails</p> </NavLink>
               </div>
               <div className="item">
                  <NavLink to='/ahome/pagoo'>  <p>Coffee</p> </NavLink>
               </div>

               <div className="item">
                  <NavLink to='/cart'></NavLink>
               </div>

            </div>
            </div>
          </nav>
            </div>
            {sticky && (
        <div
          style={{
            height: `${stickyRef.current?.clientHeight}px`
          }}
        />
      )}
      </div>
      </div>
    </div>
  )
}

export default NavMenu
