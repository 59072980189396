import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebaseApp from '../credenciales'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getFirestore, doc, setDoc, updateDoc  } from 'firebase/firestore'

function CallModal ({ isCallModal, setIsCallModal, usuarioGlobal }) {
  const firestore = getFirestore(firebaseApp)

  const call = 'Call Waiter from Table'

  async function anadirTarea (e) {
    e.preventDefault()
    var gat = usuarioGlobal.email
    var resul = gat.substr(0, 3)

    const users = usuarioGlobal.uid
    const idFrom = users
    const idTo = 'mZ11tlXbIocuQ0Ih2Dki47p03Xk2'
    const type = 0
    const msgs = call + ' ' + resul
    const city = ''
  

    var idmm = new Date().setMilliseconds(123)
    var idss = new Date()
    var ids = String(idmm)

    const nvoArrayTareas = [
      //   ...arrayTareas,
      {
        content: city,
        idFrom: idFrom,
        idTo: idTo,
        msgs: msgs,
        timestamp: ids,
        createOn: idss,
        type: type
      }
    ]

    const mass = '1'
    const sure = '2'

    const tareasSegun = [
       {
        
         mass: mass,
         sure: sure
       }
     ]

    
    //var number = parseInt(resul)
    // const tareasSegun = [
    //   {
    //     cid: {idFrom:idFrom },
    //     createOn:idss,
    //     code:idFrom,
    //     name: resul,
    //     mass: mass,
    //     sure: sure,
    //     number:number,
    //   }
    // ]


    const docuRef = doc(
      firestore,
      `messages`,
      `${idTo}-${users}`,
      `${idTo}-${users}`,
      `${ids}`
    )

    const doRef = doc(firestore, `messages`, `${idTo}-${users}`)

    await setDoc(docuRef, ...nvoArrayTareas).then(
      console.log('esto es nvo ', nvoArrayTareas),

       await updateDoc(doRef, ...tareasSegun).then(
         console.log('esto es tareasSegun ', tareasSegun),

      // await setDoc(doRef, ...tareasSegun).then(
      //   console.log('esto es tareasSegun ', tareasSegun),

        toast.success(`Call Waiter Sending`, {
          position: "top-center",
          autoClose: 3000
        }),
        await setIsCallModal(false)
      )
    )
  }

  return (
    <Modal
      show={isCallModal}
      onHide={() => {
        setIsCallModal(false)
      }}
    >
      <Modal.Header>
        <div>
          {(() => {
            if (usuarioGlobal) {
              var gat = usuarioGlobal.email
              var resul = gat.substr(0, 3)
              return (
                <>
                  <div className='alin-cont alin displa w-100'>
                    <h3 className='hbb txal-ce cabece w-100'>
                      Call Waiter to the Table: {resul}
                    </h3>
                  </div>
                </>
              )
            } else {
              return (
                <div>
                  <div className='alin-cont alin displa w-100'>
                    <h3 className='hbb txal-ce cabece w-100'>
                      {' '}
                      Enter Your Table in the previus page{' '}
                    </h3>
                  </div>
                </div>
              )
            }
          })()}
        </div>
        {/* <Modal.Title> Call the Waiter to Table  </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className='cart-it displa w-100'>
          <form onSubmit={anadirTarea}>
            <div className='alin-cont alin displa w-100 m-t20'>
              <div className='alin-cont alin displa w-100'>
                {/* <label className='alin m-b10s'>
                      Call Waiter
                    </label> */}
                <input
                  type='hidden'
                  id='formDescripcion'
                  name='formDescripcion'
                  placeholder='Enter Suggestion'
                  value={call}
                />
              </div>
              <button className='btn-conect alin' type='sumbit'>
                Call Waiter
              </button>

              <div className='alin-cont alin displa w-100'></div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        
    <Button
          variant='secondary'
          onClick={() => {
            setIsCallModal(false)
          }}
        >
          Cancell
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CallModal
